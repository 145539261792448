import React from 'react';
import Helmet from 'react-helmet';

import { useStaticQuery, graphql } from 'gatsby';

interface Meta {
  name: string | undefined;
  content: string | undefined;
  property: string | undefined;
}

interface SEOProps {
  description?: string;
  lang?: string;
  meta?: Meta[];
  keywords?: string[];
  title: string;
}

export function SEO(props: SEOProps) {
  const { description, lang, title } = props;

  const keywords = props.keywords || [];

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const meta = [
    ...(props.meta || []),
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ].concat(
    keywords.length > 0
      ? {
        name: `keywords`,
        content: keywords.join(`, `),
      }
      : []
  );

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={meta}
    />
  );
}
